import React from 'react'
import '../pagesCss/Loader.css'
function Loader() {
  return (
    <div className='loaderContainer'>
        <div className="load">

        </div>
    </div>
  )
}

export default Loader